html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Poppins", sans-serif;
  -webkit-font-feature-settings: "kern", "liga", "clig", "calt", "lnum", "zero";
  font-feature-settings: "kern", "liga", "clig", "calt", "lnum", "zero";
  font-kerning: normal;
  font-variant-ligatures: common-ligatures contextual;
  font-variant-numeric: lining-nums slashed-zero;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

/* https://twitter.com/AllThingsSmitty/status/1254151507412496384 */

body {
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-color: #49477c; /* akkkaa rgb(73, 71, 124); */
}

body.game-mood-neutral {
  background-image: linear-gradient(
    -180deg,
    rgba(73, 71, 124, 1) 0%,
    rgba(77, 100, 138, 1) 100%
  );
}

body.game-mood-positive {
  background-image: linear-gradient(
    -180deg,
    rgba(73, 71, 124, 1) 0%,
    #adcee1 100%
  );
}

body.game-mood-negative {
  background-image: linear-gradient(
    -180deg,
    rgba(73, 71, 124, 1) 0%,
    #cd2500 100%
  );
}

@media screen and (min-height: 480px) {
  body.game-play {
    min-height: -webkit-fill-available;
    overflow: hidden;
  }
  .game-play .app {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

.screen {
  height: 100%;
}

.screen.screen--menu,
.screen.screen--credits,
.screen.screen--help {
  display: grid;
  text-align: center;
  margin: 16px;
}

.screen.screen--menu {
  grid-template-rows: 4fr 7fr 64px;
}

@media screen and (min-height: 321px) {
  .screen.screen--menu {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 1px;
  }
}

@media screen and (min-height: 456px) {
  .screen.screen--menu {
    grid-template-rows: 2fr 3fr 64px;
  }
}

.logo--menu {
  max-width: 240px;
  margin: auto;
}

@media screen and (max-width: 240px) {
  .logo--menu {
    max-width: 160px;
    margin: auto;
  }
  .screen.screen--menu,
  .screen.screen--credits,
  .screen.screen--help {
    margin: 8px;
  }
}

.tm-logo {
  height: 48px;
  vertical-align: bottom;
}

@media screen and (min-width: 480px) {
  .screen.screen--menu,
  .screen.screen--credits,
  .screen.screen--help {
    margin: 16px auto;
  }
}

.screen .game-board {
  display: grid;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  justify-items: stretch;
  padding: 0 16px;
}

.screen--game .game-board {
  grid-template-columns: auto;
  grid-template-rows: 74px auto 180px;
  grid-template-areas:
    "header"
    "main"
    "footer";
}
@media screen and (max-height: 320px) {
  .screen--game .game-board {
    padding: 0 8px;
  }
}

@media screen and (max-height: 455px) {
  .screen--game .game-board {
    grid-template-rows: 56px 1fr 180px;
  }
}

@media screen and (min-width: 480px) {
  .screen .game-board {
    margin: 24px auto;
  }
  .screen--game .game-board {
    grid-template-rows: 84px 1fr 180px;
  }
  .screen--menu,
  .screen--help,
  .screen--credits {
    margin: 24px auto;
    max-width: 480px;
  }
}

.screen--credits__main {
  border-bottom: solid 1px rgb(119, 142, 186);
  margin-bottom: 32px;
}

.screen--game .game-board.game-board--story {
  grid-template-rows: 16px auto 140px;
}

.game-board__header {
  grid-area: header;

  display: flex;
  flex-direction: row;
  align-items: center;
}

/* push header to screen top */
@media screen and (min-height: 569px) {
  .game-board__header {
    padding-bottom: 8px;
  }
}
@media screen and (min-height: 668px) {
  .game-board__header {
    padding-bottom: 16px;
  }
}

/* Using FadeIn that generates two containers, the first one is for GameValues */
.game-board__header > div:first-child {
  flex: 1;
}

.game-board__card,
.game-board__gameover {
  grid-area: main;
}

.game-board__buttons {
  grid-area: footer;
}

h1 {
  color: white;
  font-size: 24px;
}

body,
p {
  font-size: 16px;
  line-height: 1.5;
  color: white;
}

@media screen and (min-height: 736px) {
  h1 {
    color: white;
    font-size: 28px;
  }

  body,
  p {
    font-size: 18px;
    line-height: 1.5;
  }
}

a {
  color: white;
  text-decoration: none;
}

p {
  margin: 0;
  padding: 8px 0;
}

p:last-of-type {
  padding-bottom: 0;
}

@media screen and (min-width: 480px) {
  p { padding: 16px 0; }
}

/* boardgame.io game board container */
.bgio-client {
  height: 100%;
}

.game-board {
  max-width: 480px;
  box-sizing: border-box;

  color: white;
  text-align: center;
}

.game-board__card {
  flex: 1;
  display: flex;
}

.game-board__buttons {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  padding-bottom: 16px;
}

.screen--help {
  align-items: center;
  justify-content: center;
}

.screen--help__grid {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 50% 50%;
  text-align: center;
  padding-top: 32px;
  padding-bottom: 32px;
}

.screen--help__grid div {
  color: white;
}

@media screen and (min-width: 480px) {
  .screen--help__grid {
    grid-gap: 16px;
  }
}

.screen--help__text {
  color: white;
  line-height: 24px;
  margin: 0 32px;
  flex: 1;
  max-width: 768px;
}

.screen--credits {
  text-align: center;
}

.screen--credits__logo {
  margin-bottom: 64px;
  margin-top: 32px;
  color: white;
}

.screen--credits__logo a {
  text-decoration: underline;
}

.screen--credits__row {
  color: white;
  margin-bottom: 24px;
  font-size: 14px;
}

.screen--credits__row h3 {
  margin: 0;
  font-size: 18px;
}

.screen--credits__row p {
  margin: 0;
  font-size: 14px;
}

.screen--credits__button {
  margin-top: 64px;
  display: block;
}

/*
 * src/GameValues.js
 */
.game-values {
  flex: 1; /* does not work with FadeIn */

  display: flex;
  align-items: center;
  flex-direction: row;

  background: #2c2a51;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

.game-values__value {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6px;
}
@media screen and (min-height: 668px) {
  .game-values__value {
    padding: 8px;
  }
}

.game-values__value > span {
  /* like 40% */
  font-size: 12px;
  line-height: 1.2;
  font-weight: 600;
}

/*
 * src/MenuButton.js
 */
.menu-button {
  margin-left: 8px;
  padding-left: 8px;
  height: 100%;

  /* magic, by default makes burge cendered*/
  display: flex;
}
.menu-button > img {
  transform: scale(0.6);
}

@media screen and (min-width: 340px) {
  .menu-button {
    margin-left: 16px;
    padding-left: 16px;
  }
  .menu-button > img {
    transform: scale(0.8);
  }
}

/*
 * src/ScreenButton.js
 */

.screen-button {
  background-color: rgb(39, 30, 70);
  flex: 1;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0 8px 16px -10px rgba(61, 61, 82, 1);
  margin-bottom: 16px;
  text-align: center;
  display: block;
}

.screen-button:hover {
  background-color: rgb(49, 34, 101);
  box-shadow: 0 8px 16px -10px rgb(66, 29, 150);
}

.screen-button--primary {
  background-color: rgb(210, 1, 1);
}
.screen-button--primary:hover {
  background-color: #f00;
  box-shadow: 0 8px 16px -10px rgb(255, 0, 0);
}

.screen-button--small {
  padding: 8px;
  margin-bottom: 8px;
}
.screen-button--small:hover {
  background-color: #f00;
  box-shadow: 0 8px 16px -10px rgb(255, 0, 0);
}


/*
 * src/GameButton.js
 */

.game-button {
  white-space: normal;
  margin: 0;
  padding: 4px 8px;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: #fff;
  line-height: 1.6;
  font-size: 14px;
  border-radius: 4px;
}

.game-button:hover {
  background-color: rgba(0, 0, 0, 0.3);
}
@media screen and (min-height: 668px) {
  .game-button {
    font-size: 16px;
  }
}

.game-button + .game-button {
  margin-top: 16px;
}
.game-board__buttons .game-button--placeholder,
.game-board__buttons .screen-button {
  padding: 4px 8px;
  line-height: 28px;
  flex: none;
}

.game-button--secondary {
  background-color: unset;
}

.game-button--secondary:hover {
  background-color: rgba(255, 0, 0, 0.2);
}

.game-button--placeholder {
  background: transparent !important;
  touch-action: none;
  outline: none;
}

/*
 * src/ScreenHelp.js
 */

.game-over {
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

.game-over .illustration {
  margin-bottom: 0;
}

.game-over.game-over--overflow {
  background: radial-gradient(54% 72.5% at 49.16% 72.5%, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%) repeat-x bottom -2px center;
  background-size: 100% 16px;
}

.game-over__week {
  color: #fff;
  font-size: 14px;
}

/*
 * src/Card.js
 */
.card {
  background-color: #fff;
  box-shadow: 0 8px 16px -10px rgba(61, 61, 82, 1);

  border-radius: 4px;
  width: 100%;
  min-height: 260px;
  box-sizing: border-box;
  padding: 9px;
  text-align: center;

  display: flex;
  flex-direction: column;

  position: relative;
  /* because of absolutely positioned week no span */
}

.card__name, .card__text { color: #333; }
.card p:last-of-type {
  padding-bottom: 0;
}

@media screen and (orientation: portrait) and (min-height: 321px) {
  .card {
    max-height: calc(100vh - 264px);
  }

  .game-over {
    max-height: calc(100vh - 256px);
    overflow: auto;
  }

  .game-board--story .card {
    max-height: calc(100vh - 216px);
  }
}

@media screen and (min-height: 553px) {
  .card {
    min-height: 364px;
    padding: 18px;
  }

  .game-over {
    max-height: calc(100vh - 272px);
    overflow: auto;
  }

  .game-board--story .card {
    max-height: 400px;
  }
}

@media screen and (min-height: 560px) {
  .card {
    max-height: calc(100vh - 200px);
  }

  .game-over {
    max-height: calc(100vh - 256px);
    overflow: auto;
  }

  .game-board--story .card {
    max-height: calc(100vh - 156px);
  }
}

@media screen and (min-width: 840px) {
  .card {
    max-height: calc(100vh - 260px);
  }
  .game-board--story .card {
    max-height: calc(100vh - 216px);
  }
}

.card--incident,
.card--effect,
.card--tutorial {
  display: flex;
  justify-content: center;
}

.card--tutorial > div {
  flex: 1;
  display: flex;
  align-items: center;
}

.card--incident p,
.card--effect p,
.card--tutorial p {
  margin: 0;
  padding: 8px 0;
}

.card__img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.card__img--story,
.card__img--story .illustration {
  margin: 0;
}

.card__text {
  padding: 4px 0 16px;
}

.card__text--effect {
  font-style: italic;
  color: #555;
}

.card__text--story {
  color: #555;
  text-align: left;
  overflow: auto;
}

.card__week {
  color: #999;
  font-size: 14px;
  font-weight: normal;
  position: absolute;
  top: 16px;
  right: 16px;
}

.card__name {
  margin: 8px 0 0 0;
  font-size: 16px;
}

.card__name--story {
  text-align: left;
}

.card__text {
  margin: 0;
  font-size: 14px;
}

@media screen and (min-height: 667px) and (min-width: 360px) {
  .card__name {
    font-size: 18px;
  }

  .card__text {
    font-size: 16px;
  }
}

@media screen and (min-height: 812px) and (min-width: 360px) {
  .card__name {
    margin: 16px 0 8px;
    font-size: 22px;
  }

  .card__text {
    font-size: 18px;
  }
}

.card__animated--image {
  flex-grow: 1;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

@media screen and (min-width: 480px) {
  .card__animated--image {
    margin: 0 32px;
  }
}

/*
 * src/Illustration.js
 */
.illustration {
  width: 100%;
  height: 100%;
  margin-bottom: 16px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

/*
 * src/ValueIcon.js
 */
svg.value-icon {
  --size: 32px;
  width: var(--size);
  height: var(--size);
  margin: 4px;
}

@media screen and (max-width: 319px) {
  .game-values__value svg.value-icon {
    --size: 20px;
  }
}

@media screen and (min-width: 320px) {
  .game-values__value svg.value-icon {
    --size: 24px;
  }
}
@media screen and (min-height: 668px) {
  .game-values__value svg.value-icon {
    --size: 32px;
  }
}

.tooltip-overlay .rmc-tooltip-inner {
  min-height: auto;
}

/*
 * src/TutorialValues.js
 */
.tutorial-values {
  display: flex;
  justify-content: center;
}
.tutorial-values > div {
  flex: 1;
}
.tutorial-values__label {
  margin: 0;
  padding: 0 !important;
  color: #555;
  font-size: 13px;
  text-transform: uppercase;
}
.tutorial-values__item > img {
  width: 32px;
  height: 32px;
}



.feedback-grid {
  display: grid;

  max-width: 480px;
  margin: 0 auto;
  text-align: center;

  box-sizing: border-box;
  justify-items: stretch;
  padding: 0 16px;

  position: fixed;
  top: 0; left: 0; right: 0; bottom: 1px;

  grid-template-columns: auto;
  grid-template-rows: 20vh 1fr auto;
}

.feedback-grid__header,
.feedback-grid__content,
.feedback-grid__footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 16px 0;
  box-sizing: border-box;
}

.feedback-grid__content textarea {
  flex: 1;
}
.feedback-grid__content p {
  font-size: 80%;
  padding: 8px 0;
}

.feedback-grid__footer {
  justify-content: flex-end;
}

.screen--feedback textarea {
  background-color: #fff;
  box-shadow: 0 8px 16px -10px rgba(61, 61, 82, 1);
  border-radius: 4px;
  font-size: 16px;
  line-height: 1.5;
  color: #78799A;
  padding: 16px;
  box-sizing: border-box;
}

